import {
  FC,
  InputHTMLAttributes,
  OptionHTMLAttributes,
  useCallback,
} from 'react'
import s from './Radios.module.css'

const Radios: FC<RadiosProps> = (props) => {
  const { items, onChange, ...passingProps } = props

  const handleChange: Required<
    InputHTMLAttributes<HTMLInputElement>
  >['onChange'] = useCallback(
    (e) => {
      onChange?.(e.currentTarget.value)
    },
    [onChange]
  )

  return (
    <div className={s.root}>
      {items.map((item, index) => (
        <label key={String(item.value)} className={s.item}>
          <input
            type="radio"
            {...passingProps}
            value={item.value}
            defaultChecked={index === 0}
            className={s.control}
            onChange={handleChange}
          />
          <span className={s.fake} />
          {item.label || item.value}
        </label>
      ))}
    </div>
  )
}

type RadiosProps = Pick<InputHTMLAttributes<HTMLInputElement>, 'name'> & {
  items: Pick<OptionHTMLAttributes<HTMLOptionElement>, 'label' | 'value'>[]
  onChange?: (value: string) => void
}

export { Radios }
export type { RadiosProps }
