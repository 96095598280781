import moment from 'moment-timezone'
import { FC, useCallback, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import useSWR from 'swr'
import { Api } from '../../api'
import { Button } from '../../components/Button'
import {
  Field,
  Form,
  FormProps,
  FormRow,
  Input,
  PasswordInput,
  Radios,
  RadiosProps,
  Select,
  Values,
} from '../../components/form'
import { Checkbox } from '../../components/form/Checkbox'
import { Loading } from '../../components/Loading'
import { Logo } from '../../components/Logo'
import { Modal } from '../../components/Modal'
import { Notice } from '../../components/Notice'
import { Page } from '../../components/Page'
import { formatMobile } from '../../helpers'
import { useSwitch } from '../../hooks/switch'
import { ReactComponent as Magnifier1 } from '../../icons/magnifier-1.svg'
import { Abn, BnItem, Nzbn } from '../../third-party/bn'
import s from './Home.module.css'

const Home: FC = () => {
  const {
    on: bnResultOpened,
    open: openBnResult,
    close: closeBnResult,
  } = useSwitch()
  const {
    on: timezoneDisabled,
    open: disableTimezone,
    close: enableTimezone,
  } = useSwitch()

  const { data: referrer } = useSWR<Dto.Referrer>(
    referrerCode && ['onboarding/referrer', { code: referrerCode }]
  )

  const { on: pending, open: startPending, close: stopPending } = useSwitch()

  const [step, setStep] = useState(0)
  const [location, setLocation] = useState('Australia')
  const [bnKeyword, setBnKeyword] = useState('')
  const [bnResult, setBnResult] = useState<BnItem[]>()
  const [selectedBn, setSelectedBn] = useState<BnItem>()
  const [searchFailed, setSearchFailed] = useState(false)
  const [countryIndex, setCountryIndex] = useState(0)

  const allValues = useRef<Values>()
  const signUpDto = useRef<Dto.SignUp>()

  const bnConfig = bnConfigs[location]

  const createHandleClickBn = (bn: BnItem) => () => {
    setSelectedBn(bn)
    closeBnResult()
  }
  const next = useCallback(() => {
    setStep((prev) => prev + 1)
  }, [])

  const handleStep0Submit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      allValues.current = values
      startPending()

      try {
        if (
          !(await Api.get('onboarding/user_available', {
            email: allValues.current.email,
          }))
        ) {
          toast.error(
            'Sorry but this email has already been used, please use a different email'
          )
          return
        }

        next()

        Api.post('onboarding/contacts', {
          first_name: allValues.current['first-name'],
          last_name: allValues.current['last-name'],
          email: allValues.current.email,
          referrer_id: referrer?.id,
        })
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [next, referrer?.id, startPending, stopPending]
  )

  const handleLocationChange: Required<RadiosProps>['onChange'] = useCallback(
    (value) => {
      setLocation(value)
      setSelectedBn(undefined)
    },
    []
  )

  const handleBnSearchSubmit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      const keyword = values['bn-keyword'] as string

      if (!keyword) {
        toast.error(
          `Please add your ${bnConfig.bnName} number or business name before you continue`,
          { id: 'bn' }
        )
        return
      }

      setBnResult(undefined)
      openBnResult()

      try {
        const result = await (location === 'Australia' ? Abn : Nzbn).search(
          keyword
        )
        setBnResult(result)
      } catch (error) {
        if (error instanceof Error) {
          toast.error(
            `Searching failed: ${error.message}\nPlease input manually.`
          )
        }

        closeBnResult()
        setSearchFailed(true)
      }

      setBnKeyword(keyword)
    },
    [bnConfig.bnName, closeBnResult, location, openBnResult]
  )

  const handleReSearchClick = useCallback(() => {
    setSelectedBn(undefined)
  }, [])

  const handleStep1Change: Required<FormProps>['onChange'] = useCallback(
    (values) => {
      console.log(values)
      setCountryIndex(Number(values['country-index']))

      if (values['auto-timezone']) {
        disableTimezone()
      } else {
        enableTimezone()
      }
    },
    [disableTimezone, enableTimezone]
  )

  const handleStep1Submit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      if (!searchFailed && !selectedBn) {
        ;(document.getElementById('bn-form') as HTMLFormElement).requestSubmit()
        return
      }

      allValues.current = { ...allValues.current, ...values }
      startPending()

      try {
        signUpDto.current = await Api.post('onboarding/sign_up', {
          first_name: allValues.current['first-name'],
          last_name: allValues.current['last-name'],
          email: allValues.current.email,
          password: allValues.current.password,
          timezone: allValues.current['auto-timezone']
            ? moment.tz.guess()
            : allValues.current.timezone,
          ...(selectedBn
            ? {
                name: selectedBn.name || allValues.current.name,
                [bnConfig.bnName.toLowerCase()]: selectedBn.bn,
                abn_status: selectedBn.abnStatus,
                state: selectedBn.state,
                postcode: selectedBn.postcode,
              }
            : {
                name: allValues.current.name,
                [bnConfig.bnName.toLowerCase()]: allValues.current.bn,
              }),
          studio_type: allValues.current.type,
          customers_size: allValues.current.members,
          mobile: formatMobile(
            countries[countryIndex].code,
            allValues.current.phone as string
          ),
          referrer_id: referrer?.id,
          starter_guide: '',
        })
        next()
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [
      bnConfig.bnName,
      countryIndex,
      next,
      referrer?.id,
      searchFailed,
      selectedBn,
      startPending,
      stopPending,
    ]
  )

  const handleResendClick = useCallback(async () => {
    await Api.post('onboarding/reverify', { gym_id: signUpDto.current?.gym_id })
    toast.success('Email is sent')
  }, [])

  return (
    <>
      <Page background={step >= 2 ? 'background-2.jpg' : 'background-1.jpg'}>
        <div data-step={step} className={s.root}>
          {step <= 1 ? (
            <div className={s.form}>
              <section className={s.step}>
                {step === 0 && (
                  <>
                    <header className={s.header}>
                      <div className={s.logo}>
                        <Logo width="9rem" />
                      </div>
                      <div>
                        Just a couple steps away from creating your XODA account
                      </div>
                    </header>
                    <Form
                      validation={step0Validation}
                      onSubmit={handleStep0Submit}
                    >
                      <FormRow>
                        <Field
                          name="first-name"
                          label="First name"
                          control={<Input placeholder="First name" autoFocus />}
                        />
                        <Field
                          name="last-name"
                          label="Last name"
                          control={<Input placeholder="Last name" />}
                        />
                      </FormRow>
                      <Field
                        name="email"
                        label="Email address"
                        control={
                          <Input type="email" placeholder="Email address" />
                        }
                      />
                      <Field
                        name="password"
                        label="Password"
                        control={
                          <PasswordInput
                            placeholder="Create a password"
                            autoComplete="new-password"
                          />
                        }
                        tip="Use 8 or more characters with a mix of letters, numbers & symbols"
                      />
                      <Field
                        name="password-again"
                        label="Confirm password"
                        control={
                          <PasswordInput placeholder="Confirm password" />
                        }
                      />
                      <Button type="submit" block pending={pending}>
                        Continue
                      </Button>
                    </Form>
                    <div className={s['guide-slogan']}>
                      No credit card required
                    </div>
                    <div className={s.terms}>
                      By continuing, you agree to XODA’s{' '}
                      <a
                        href="https://xoda.com/terms-conditions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms
                      </a>
                      , and acknowledge you have read our{' '}
                      <a
                        href="https://xoda.com/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        privacy policy
                      </a>
                      .
                    </div>
                  </>
                )}
                {step === 1 && (
                  <>
                    <header className={s.header}>
                      <div className={s.logo}>
                        <Logo width="6rem" center={false} />
                      </div>
                      <div>Complete your business details</div>
                    </header>
                    <Form
                      validation={{
                        bn: [
                          {
                            test: 'valueMissing',
                            message: `${bnConfig.bnName} required`,
                          },
                        ],
                        name: [
                          {
                            test: 'valueMissing',
                            message: 'Business name required',
                          },
                        ],
                      }}
                      onChange={handleStep1Change}
                      onSubmit={handleStep1Submit}
                    >
                      <Field
                        name="location"
                        label="Business Location"
                        control={
                          <Radios
                            items={[
                              { value: 'Australia' },
                              { value: 'New Zealand' },
                            ]}
                            onChange={handleLocationChange}
                          />
                        }
                      />
                      {selectedBn ? (
                        <>
                          <Field
                            label={bnConfig.bnName}
                            control={<>{selectedBn.bn}</>}
                          />
                          <Field
                            name="name"
                            label="Business name"
                            control={
                              selectedBn.name ? (
                                <>{selectedBn.name}</>
                              ) : (
                                <Input autoFocus />
                              )
                            }
                          />
                          <div className={s['back-search']}>
                            <Button
                              variant="link"
                              onClick={handleReSearchClick}
                            >
                              Back to search
                            </Button>
                          </div>
                        </>
                      ) : searchFailed ? (
                        <>
                          <Field
                            name="bn"
                            label={`${bnConfig.bnName}`}
                            control={<Input autoFocus />}
                          />
                          <Field
                            name="name"
                            label="Business name"
                            control={<Input />}
                          />
                        </>
                      ) : (
                        <Field
                          name="bn-keyword"
                          label={`${bnConfig.bnName}/Business name search`}
                          control={
                            <Input
                              form="bn-form"
                              defaultValue={bnKeyword}
                              placeholder={`Search ${bnConfig.bnName} or business name`}
                              suffixIcon={<Magnifier1 />}
                              onClickSuffix="submit"
                              autoFocus
                            />
                          }
                        />
                      )}
                      <Field
                        name="type"
                        label="Gym/Studio type"
                        control={
                          <Select
                            options={[
                              { value: 'Bootcamp (Outdoor)' },
                              { value: 'Bootcamp (Studio)' },
                              { value: 'Boxing' },
                              { value: 'CrossFit' },
                              { value: 'Health Club' },
                              { value: 'Martial Arts' },
                              { value: 'Personal Training' },
                              { value: 'Pilates' },
                              { value: 'Spin' },
                              { value: 'Strength and Conditioning' },
                              { value: 'Yoga' },
                              { value: 'Weightlifting' },
                              { value: 'Other' },
                            ]}
                          />
                        }
                      />
                      <Field
                        name="members"
                        label="Number of active members"
                        control={
                          <Select
                            options={[
                              { value: '0-50' },
                              { value: '50-200' },
                              { value: '200-500' },
                              { value: '500+' },
                            ]}
                          />
                        }
                      />
                      <Field
                        name="timezone"
                        label="Timezone"
                        control={
                          !timezoneDisabled ? (
                            <Select
                              options={[
                                ...moment.tz
                                  .names()
                                  .map((item) => ({ value: item })),
                              ]}
                              defaultValue={moment.tz.guess()}
                            />
                          ) : (
                            <></>
                          )
                        }
                        after={
                          <Checkbox
                            name="auto-timezone"
                            label="Set my timezone automatically"
                            size="s"
                          />
                        }
                      />
                      <Field
                        name="phone"
                        label="Phone"
                        control={
                          <Input
                            type="tel"
                            required={false}
                            placeholder={countries[countryIndex].example}
                            prefix={
                              <>
                                <Select
                                  name="country-index"
                                  options={countries.map((country, index) => ({
                                    label: country.label,
                                    value: index,
                                  }))}
                                  emoji
                                />
                                <span
                                  className={s['country-code']}
                                >{`+${countries[countryIndex].code}`}</span>
                              </>
                            }
                          />
                        }
                      />
                      <Button type="submit" block pending={pending}>
                        Create Account
                      </Button>
                      <div className={s['guide-slogan']}>Almost done!</div>
                    </Form>
                    <Form id="bn-form" onSubmit={handleBnSearchSubmit}></Form>
                    <Modal
                      opened={bnResultOpened}
                      onRequestClose={closeBnResult}
                    >
                      <header className={s['bn-result-header']}>
                        <h1 className={s['bn-result-title']}>
                          {bnConfig.bnName} Search Results
                        </h1>
                        {!!bnResult?.length && (
                          <div className={s['bn-result-subtitle']}>
                            Select a matching {bnConfig.bnName} from the results
                            below
                          </div>
                        )}
                      </header>
                      <table className={s['bn-result']}>
                        <thead>
                          <tr>
                            <th scope="col">{bnConfig.bnName}</th>
                            <th scope="col">Name</th>
                            <th scope="col">Location</th>
                          </tr>
                        </thead>
                        {bnResult && (
                          <tbody>
                            {bnResult.map((item) => (
                              <tr
                                key={item.bn + item.name}
                                onClick={createHandleClickBn(item)}
                              >
                                <td>{item.bn}</td>
                                <td>{item.name}</td>
                                <td>{item.location}</td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                      {!bnResult ? (
                        <Loading />
                      ) : (
                        !bnResult.length && (
                          <div
                            className={s['bn-empty']}
                            style={{
                              // Cannot use images in `public` directory as static assets in CSS files.
                              backgroundImage: 'url(/images/warning-1.png)',
                            }}
                          >
                            Sorry no results found,
                            <br />
                            <Button variant="link" onClick={closeBnResult}>
                              go back
                            </Button>{' '}
                            to search again.
                          </div>
                        )
                      )}
                    </Modal>
                  </>
                )}
              </section>
            </div>
          ) : (
            <Notice
              footer={
                <>
                  Not getting the email?
                  <br />
                  Check your spam/junk folder.
                </>
              }
              shadow
            >
              <div className={s.welcome}>
                <div>
                  Welcome to XODA! We sent an email to{' '}
                  {String(allValues.current?.email)}
                </div>
                <div>Check your inbox to activate your account.</div>
              </div>
              <div>
                <div className={s['email-tip']}>Didn’t get your email?</div>
                <Button variant="ghost" onClick={handleResendClick}>
                  Resend email
                </Button>
              </div>
            </Notice>
          )}
        </div>
      </Page>
    </>
  )
}

const referrerCode = new URL(window.location.href).searchParams.get('referrer')

const bnConfigs: Record<string, { bnName: string }> = {
  Australia: {
    bnName: 'ABN',
  },
  'New Zealand': {
    bnName: 'NZBN',
  },
}

const step0Validation: FormProps['validation'] = {
  'first-name': [
    {
      test: 'valueMissing',
      message: 'First name required',
    },
  ],
  'last-name': [
    {
      test: 'valueMissing',
      message: 'Last name required',
    },
  ],
  email: [
    {
      test: 'valueMissing',
      message: 'Email address required',
    },
    {
      test: 'typeMismatch',
      message: 'Invalid email address',
    },
  ],
  password: [
    {
      test: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      message:
        'Use 8 or more characters with a mix of letters, numbers & symbols',
    },
  ],
  'password-again': [
    {
      test: 'valueMissing',
      message: 'Confirm password required',
    },
    {
      test: (value, values) => value === values.password,
      message: "Passwords don't match, try again.",
    },
  ],
}

const countries: { label: string; code: string; example: string }[] = [
  {
    label: '🇦🇺',
    code: '61',
    example: '0412 345 678',
  },
  {
    label: '🇳🇿',
    code: '64',
    example: '021 123 4567',
  },
]

export { Home }
